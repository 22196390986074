import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { ApiConfigModel } from '../../shared/models/api-config.model';
import { Pagination } from '../../shared/models/pagination.model';
import { GenericService } from '../../shared/services/genericService/genericService.service';


@Injectable()
export class PedidoService {

    apiConfig: ApiConfigModel = {
        Debug: false,
        Prefixo: '/Pedido',
        UrlDebug: 'localhost:3300'
    };

    paginacao: Pagination = {
        total: 0,
        deslocamento: 0,
        limite: 10,
        ordenarPor: 'Id',
        pagina: 1
    }

    constructor(
        private _genericSvc: GenericService,
        private _routerActive: ActivatedRoute,
        private _router: Router
    ) {
    }

    public atualizarRota(filtros: any, rota: any) {
        let parametros = this._genericSvc.removerParametros(filtros);

        var x = this._genericSvc.validarParametrosPadroes(parametros);

        this.paginacao = { ...this.paginacao, ...x };

        parametros.deslocamento = this.paginacao.deslocamento;

        let redirect = this._genericSvc.gerarRota(rota, 3);
        this._router.navigate([redirect], { queryParams: parametros });

        this._genericSvc.listaDeEventos("pedidos-filtros").emit(parametros);
    }

    public upload() {
        console.log("pedidoupload");
    }
}