import { Injectable } from '@angular/core';
import { ApiConfigModel } from '../../shared/models/api-config.model';

@Injectable()
export class CidadeService {

  apiConfig: ApiConfigModel = {
    Debug: false,
    Prefixo: '/Cidade',
    UrlDebug: 'localhost:3300'
  };

  constructor() { }

}
